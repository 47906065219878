<template>
  <el-row class="table">
    <span class="title">提交记录</span>
    <el-row class="row-bg">
      <el-input
        v-show="Boolean(!problem_id)"
        placeholder="请输入内容"
        v-model.trim="inputVal"
        class="tb-search-input"
        @keyup.enter.native="search"
      >
        <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px;">
          <el-option label="问题标题" value="1"></el-option>
          <el-option label="问题id" value="2"></el-option>
        </el-select>
      </el-input>
      <el-input
        class="tb-sm-input"
        placeholder="请输入姓名"
        v-model="name"
        @keyup.enter.native="search"
      ></el-input>
      <el-input
        class="tb-sm-input"
        placeholder="请输入学号"
        v-model="loginName"
        @keyup.enter.native="search"
      ></el-input>
      <el-button @click="search" type="primary" size="medium" class="tb-button">搜索</el-button>
      <el-button @click="reset" type="primary" size="medium" class="tb-button">重置</el-button>
    </el-row>

    <!-- <div class="table-container"> -->
    <el-table :data="dataList" class="tb-list" border fit highlight-current-row>
      <el-table-column label="学号" align="center" prop="loginName"></el-table-column>
      <el-table-column label="姓名" align="center" prop="name"></el-table-column>
      <el-table-column label="题号" align="center" prop="contestProblemNum"></el-table-column>
      <el-table-column label="[id]标题" align="center">
        <template slot-scope="scope">
          <span>[{{ scope.row.problem_id }}]{{ scope.row.problemName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="得分" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.pass_rate * 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结果" align="center" width="130">
        <template slot-scope="scope">
          <span
            @click="subDetail(scope.row)"
            class="type-span"
            :style="'backgroundColor' + ':' + getSubTypeColor(scope.row.result)"
          >{{ getSubType(scope.row.result) }}
          </span>
        </template>
      </el-table-column>
      <!--      <el-table-column label="得分" align="center" prop="title"></el-table-column>-->
      <el-table-column label="运行时间(ms)" align="center" prop="time"></el-table-column>
      <el-table-column label="私用内存(KB)" align="center" prop="memory"></el-table-column>
      <el-table-column label="代码长度(B)" align="center" prop="code_length"></el-table-column>
      <el-table-column label="编程语言" align="center">
        <template slot-scope="scope">
          <span> {{ getLanText(scope.row.language) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" align="center" width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.in_date | dateFormat }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- </div> -->

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-row>
</template>

<script>
// import { getSubType, getLanText } from '@/utils/getStateText'
import { getSubmitHistory ,getSubmitHistoryByContest  } from '@/api/research/question'

export default {
  // watch: {
  //   dataList() {
  //     this.$nextTick(() => {
  //       this.tableHeight = document.getElementsByClassName('tb-list')[0].offsetHeight
  //       document.getElementsByClassName('table-container')[0].style.height = this.tableHeight + 'px'
  //     })
  //   }
  // },
  // mixins: [getSubType, getLanText],
  data () {
    return {
      inputVal: '',
      dataList: [],
      select: '1',
      name: '',
      loginName: '',
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      problem_id: '',
      tableHeight: '',
      type: '',
      contest_id: ''
    }
  },
  created () {
    this.problem_id = this.$route.query.problem_id
    this.type = this.$route.query.type
    // console.log('this.problem_id', this.problem_id)
    if(this.type === 'contest') {
      this.contest_id = this.$route.query.contest_id
      this.getSubmitHistoryByContest()
    }else {
      this.getSubRecordList()
    }

  },
  // mounted() {
  //   window.onresize = () => {
  //     return (() => {
  //       this.tableHeight = document.getElementsByClassName('tb-list')[0].offsetHeight
  //       document.getElementsByClassName('table-container')[0].style.height = this.tableHeight + 'px'
  //     })()
  //   }
  // },
  methods: {
    //查看活动题库
    async getSubmitHistoryByContest () {
      const res = await getSubmitHistoryByContest({
        needCount: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        contest_id:this.contest_id,
        title: this.select === '1' ? this.inputVal : '',
        problem_id: (!this.problem_id && this.select === '2') ? this.inputVal : this.problem_id,
        name: this.name,
        loginName: this.loginName
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },
    // 管理后台-获取提交记录列表
    async getSubRecordList () {
      const res = await getSubmitHistory({
        needCount: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        // problem_id: this.problem_id || '',
        problem_id: (!this.problem_id && this.select === '2') ? this.inputVal : this.problem_id,
        title: this.select === '1' ? this.inputVal : '',
        name: this.name,
        loginName: this.loginName
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 跳转详情页面
    subDetail (row) {
      let routeData = this.$router.resolve({
        path: '/research/question-subDetails',
        query: {
          resultData: JSON.stringify(row)
        }
      })
      window.open(routeData.href, '_blank')
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      if(this.type === 'contest') {
        this.getSubmitHistoryByContest()
      }else {
        this.getSubRecordList()
      }
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      if(this.type === 'contest') {
        this.getSubmitHistoryByContest()
      }else {
        this.getSubRecordList()
      }
    },

    // 搜索
    search () {
      if (this.select === '2') {
        let reg = /^[0-9]*$/
        if (!reg.test(this.inputVal)) {
          window.$msg('请输入正确的id', 2)
          return false
        }
      }
      this.pagination.currentPage = 1
      if(this.type === 'contest') {
        this.getSubmitHistoryByContest()
      }else {
        this.getSubRecordList()
      }
    },

    // 重置
    reset () {
      this.select = '1'
      this.inputVal = ''
      this.name = ''
      this.loginName = ''
    },

    // 获取语言
    getLanText (state) {
      switch (state) {
        case 0: {
          return 'C'
        }
        case 1: {
          return 'C++'
        }
        case 2: {
          return 'Pascal'
        }
        case 4: {
          return 'Ruby'
        }
        case 5: {
          return 'Bash'
        }
        case 6: {
          return 'Python'
        }
        case 7: {
          return 'php'
        }
        case 8: {
          return 'perl'
        }
        case 9: {
          return 'c#'
        }
        case 10: {
          return 'objectiveC'
        }
        case 11: {
          return 'freeBasic'
        }
        case 12: {
          return 'scheme'
        }
        case 13: {
          return 'Clang'
        }
        case 14: {
          return 'Clang++'
        }
        case 15: {
          return 'Lua'
        }
        case 16: {
          return 'JavaScript'
        }
        case 17: {
          return 'Go'
        }
      }
    },
    getSubType (state) {
      switch (state) {
        case 0:
          return '等待'
        case 1:
          return '等待重判'
        case 2:
          return '编译中'
        case 3:
          return '运行并评判'
        case 4:
          return '正确'
        case 5:
          return '格式错误'
        case 6:
          return '答案错误'
        case 7:
          return '时间超限'
        case 8:
          return '内存超限'
        case 9:
          return '输出超限'
        case 10:
          return '运行错误'
        case 11:
          return '编译错误'
        case 12:
          return '编译成功'
        case 13:
          return '运行完成'
        default:
          return '未知状态'
      }
    },
    getSubTypeColor (state) {
      switch (state) {
        case 4:
          return '#4DA54D'
        case 12:
          return '#4DA54D'
        case 13:
          return '#4DA54D'
        case 5:
          return '#CE433F'
        case 6:
          return '#CE433F'
        case 10:
          return '#CE433F'
        case 11:
          return '#CE433F'
        default:
          return '#EB9316'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
.ico-time {
  margin-right: 8px;
}
.ico-bulk {
  margin-left: 13px;
  margin-right: 8px;
}
</style>
