var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("提交记录")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c(
            "el-input",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: Boolean(!_vm.problem_id),
                  expression: "Boolean(!problem_id)",
                },
              ],
              staticClass: "tb-search-input",
              attrs: { placeholder: "请输入内容" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.search.apply(null, arguments)
                },
              },
              model: {
                value: _vm.inputVal,
                callback: function ($$v) {
                  _vm.inputVal = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "inputVal",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { slot: "prepend", placeholder: "请选择" },
                  slot: "prepend",
                  model: {
                    value: _vm.select,
                    callback: function ($$v) {
                      _vm.select = $$v
                    },
                    expression: "select",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "问题标题", value: "1" } }),
                  _c("el-option", { attrs: { label: "问题id", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-input", {
            staticClass: "tb-sm-input",
            attrs: { placeholder: "请输入姓名" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
            },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _c("el-input", {
            staticClass: "tb-sm-input",
            attrs: { placeholder: "请输入学号" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
            },
            model: {
              value: _vm.loginName,
              callback: function ($$v) {
                _vm.loginName = $$v
              },
              expression: "loginName",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.search },
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.reset },
            },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "学号", align: "center", prop: "loginName" },
          }),
          _c("el-table-column", {
            attrs: { label: "姓名", align: "center", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "题号",
              align: "center",
              prop: "contestProblemNum",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "[id]标题", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "[" +
                          _vm._s(scope.row.problem_id) +
                          "]" +
                          _vm._s(scope.row.problemName)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "得分", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.pass_rate * 100))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "结果", align: "center", width: "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "type-span",
                        style:
                          "backgroundColor" +
                          ":" +
                          _vm.getSubTypeColor(scope.row.result),
                        on: {
                          click: function ($event) {
                            return _vm.subDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.getSubType(scope.row.result)) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "运行时间(ms)", align: "center", prop: "time" },
          }),
          _c("el-table-column", {
            attrs: { label: "私用内存(KB)", align: "center", prop: "memory" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "代码长度(B)",
              align: "center",
              prop: "code_length",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "编程语言", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.getLanText(scope.row.language))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "提交时间", align: "center", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("dateFormat")(scope.row.in_date))),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }